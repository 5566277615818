import { graphql } from "gatsby"
import React from "react"
import ArchiveData from "../components/Archive/ArchiveData"
import useClickDetector from "../utils/useClickDetector"
import HeaderSEO from "../components/Header/HeaderSEO"

const ArchivePage = (props) => {
  useClickDetector()

  return <ArchiveData {...props} />
}

export const query = graphql`
  query getArchive {
    allPrismicProfile {
      edges {
        node {
          data {
            first_name {
              text
            }
            date_of_birth {
              text
            }
            time_served {
              text
            }
            age_at_interview {
              text
            }
            gist {
              text
            }
            date_of_offense
            last_name {
              text
            }
            full_name {
              text
            }
            imagepath {
              text
            }
            quote {
              text
            }
            deceased_date
            profile_picture {
              gatsbyImageData
            }
            old_picture {
              gatsbyImageData
            }
            show_in_archive
            color
            archive_video_link {
              url
            }
          }
        }
      }
    }
  }
`
export const Head = () => <HeaderSEO />
export default ArchivePage
