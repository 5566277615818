import { navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import isNull from "lodash/isNull"
import React, { useState } from "react"
import getProfileProps from "../../utils/getProfileProps"
import Options from "../Symbols/Options"
import Play from "../Symbols/Play"
import "./ArchiveTableRowLarge.css"

const ArchiveTableRowLarge = ({
  profile,
  profileIdx,
  gistProfile,
  setGistProfile,
}) => {
  const [isHover, setHover] = useState(null)

  const {
    image,
    oldImage,
    fullName,
    date_of_offense,
    age_at_offense,
    time_served,
    age_at_interview,
    archive_video_link,
  } = getProfileProps(profile)

  return (
    <div
      key={`archive-table-row-${profileIdx}`}
      onMouseEnter={() => {
        setHover(profileIdx)
      }}
      onMouseLeave={() => {
        setHover(null)
      }}
      className={`tr ${!isNull(isHover) ? "hovered" : ""} archive-table-row`}
      style={{
        backgroundColor:
          profileIdx % 2
            ? "rgba(var(--clr-off-white-rgb), 1)"
            : "rgba(var(--clr-primary-rgb), 0.1)",
      }}
    >
      <div
        className="td"
        tabIndex="0"
        aria-label={`View full interview for ${fullName}`}
        onClick={() => {
          const profileUri = fullName.toLowerCase().replace(/ /g, "_")
          archive_video_link && navigate(`/archive/${profileUri}`)
        }}
      >
        <div className="image-wrap">
          {image && (
            <GatsbyImage
              alt={`Picture of ${fullName}`}
              image={image}
              imgStyle={{
                objectFit: "cover",
                visibility: "visible",
              }}
            />
          )}
          {oldImage && (
            <GatsbyImage
              alt={`Picture of ${fullName}`}
              image={oldImage}
              imgStyle={{
                objectFit: "cover",
                visibility: "visible",
              }}
            />
          )}
        </div>
        <div
          className="full-name"
          onClick={() => {
            const profileUri = fullName.toLowerCase().replace(/ /g, "_")
            archive_video_link && navigate(`/archive/${profileUri}`)
          }}
        >
          <Play color={"white"} />
          <p>{fullName}</p>
        </div>
      </div>
      <div className="td">
        <>{date_of_offense}</>
      </div>
      <div className="td">{age_at_offense}</div>
      <div className="td">
        <>{age_at_interview}</>
      </div>
      <div className="td years-cell">
        <span>{`${time_served} years`}</span>
        <button
          className="options"
          onClick={(e) => {
            e.stopPropagation()
            setGistProfile(fullName)
          }}
        >
          <Options color="var(--clr-black)" />
        </button>
      </div>
    </div>
  )
}

export default ArchiveTableRowLarge
