import { isNull } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import useHasMounted from "../../utils/useHasMounted"
import FilterAndSearch from "../FilterAndSearch"
import Header from "../Header/Header"
import Loading from "../Loading"
import "./Archive.css"
import ArchiveActions from "./ArchiveActions"
import ArchiveGrid from "./ArchiveGrid"
import ArchiveModal from "./ArchiveModal"
import ArchiveTable from "./ArchiveTable"
import ArchiveTableSearchResults from "./ArchiveTableSearchResults"

const columns = [
  { key: "picture", label: "" },
  { key: "full_name", label: "Full Name" },
  { key: "age_at_offense", label: "Age at offense" },
  { key: "age_at_interview", label: "Age" },
  { key: "offense_date", label: "Incarcerated since" },
]

const Archive = ({ profiles = [], loading, images }) => {
  const hasMounted = useHasMounted()
  const params = useMemo(
    () =>
      new URLSearchParams(
        typeof window !== "undefined" ? window.location.search : ""
      ),
    []
  )

  const updateSearchParam = useCallback(
    (searchWords) => {
      if (!hasMounted) return null

      if (typeof window !== "undefined") {
        if (searchWords === "" || searchWords === null) {
          params.delete("search")
          window.history.replaceState({}, "", `${window.location.pathname}`)
        } else {
          params.set("search", searchWords)
          window.history.replaceState(
            {},
            "",
            `${window.location.pathname}?${params}`
          )
        }
      }
    },
    [params, hasMounted]
  )

  const [gistProfile, setGistProfile] = useState(null)
  const [sortAsc, setSortedAsc] = useState(
    typeof window !== "undefined" &&
      window.localStorage.getItem("archiveSortAsc")
      ? window.localStorage.getItem("archiveSortAsc") === "true"
      : true
  )
  const [sortType, setSortedType] = useState(
    typeof window !== "undefined" && window.localStorage.getItem("archiveSort")
      ? columns.find(
          (c) => c.key === window.localStorage.getItem("archiveSort")
        )
      : columns[1]
  )

  const [view, setView] = useState(
    params.get("search") && params.get("search") !== null
      ? "search"
      : typeof window !== "undefined" &&
        window.localStorage.getItem("isArchiveGrid") === "true"
      ? "grid"
      : "table"
  )
  const [searchWords, setSearchWords] = useState(params.get("search") || null)
  const [isSearchLoading, setLoadingSearchResults] = useState(false)
  const [searchResults, setSearchResults] = useState([])

  useEffect(() => {
    updateSearchParam(searchWords)
  }, [searchWords, updateSearchParam])

  useEffect(() => {
    if (view === "table") {
      if (typeof window !== "undefined")
        window.localStorage.removeItem("isArchiveGrid")
    } else {
      if (typeof window !== "undefined")
        window.localStorage.setItem("isArchiveGrid", "true")
    }
  }, [view])

  useEffect(() => {
    if (typeof window !== "undefined" && sortType)
      window.localStorage.setItem("archiveSort", sortType.key)
  }, [sortType])

  useEffect(() => {
    if (typeof window !== "undefined" && typeof sortAsc !== "undefined")
      window.localStorage.setItem("archiveSortAsc", sortAsc.toString())
  }, [sortAsc])

  if (!hasMounted) return null

  return (
    <div className="archive-wrap">
      <Header
        title={"Archive"}
        theme={view === "table" || view === "search" ? "light" : undefined}
        banner="ARCHIVE"
        actions={
          <>
            <FilterAndSearch
              searchWords={searchWords}
              setLoadingSearchResults={setLoadingSearchResults}
              setSearchResults={setSearchResults}
              setSearchWords={setSearchWords}
              setView={setView}
              theme={view === "table" || view === "search" ? "light" : null}
              updateSearchParam={updateSearchParam}
            />
            <ArchiveActions
              columns={columns}
              setSortedAsc={setSortedAsc}
              setSortedType={setSortedType}
              sortAsc={sortAsc}
              sortType={sortType}
              setView={setView}
              view={view}
              theme={view === "table" || view === "search" ? "light" : null}
            />
          </>
        }
      />
      <div className="archive">
        {loading && <Loading />}
        {!view ||
          (view === "grid" && !loading && (
            <ArchiveGrid
              profiles={profiles}
              images={images}
              sortType={sortType}
              sortAsc={sortAsc}
              gistProfile={gistProfile}
              setGistProfile={setGistProfile}
            />
          ))}
        {view === "table" && !loading && (
          <ArchiveTable
            images={images}
            profiles={profiles}
            sortType={sortType}
            sortAsc={sortAsc}
            setSortedAsc={setSortedAsc}
            setSortedType={setSortedType}
            gistProfile={gistProfile}
            setGistProfile={setGistProfile}
          />
        )}
        {view === "search" && !loading && (
          <ArchiveTableSearchResults
            images={images}
            isSearchLoading={isSearchLoading}
            profiles={profiles}
            searchResults={searchResults}
            searchWords={searchWords}
            sortType={sortType}
            sortAsc={sortAsc}
          />
        )}
      </div>
      {!isNull(gistProfile) && (
        <ArchiveModal
          profile={profiles.find((p) => p?.full_name?.text === gistProfile)}
          onClose={() => {
            setGistProfile(null)
          }}
        />
      )}
    </div>
  )
}

export default Archive
