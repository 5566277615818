import { navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import getProfileProps from "../../utils/getProfileProps"
import { getNameUri } from "../../utils/index.js"
import Loading from "../Loading"
import Play from "../Symbols/Play"
import "./ArchiveTableSearchResults.css"
import ArchiveTableSearchRow from "./ArchiveTableSearchRow"

const getProfileResults = (fullName, searchResults) => {
  return (
    searchResults.resultsContent
      .filter((d) => getNameUri(fullName) === Object.keys(d)[0])
      .map((d) => Object.values(d)[0])[0] || []
  )
}

const ArchiveTableSearchResults = ({
  profiles,
  searchResults,
  searchWords,
  isSearchLoading,
}) => {
  const [openRow, setOpenRow] = useState(null)

  const sortedProfiles = profiles
    .filter((profile) => {
      const profileUri = getNameUri(profile.full_name.text)
      return (
        searchResults?.resultsContent?.findIndex(
          (result) => Object.keys(result)[0] === profileUri
        ) > -1
      )
    })
    .map((profile) => {
      const { image, oldImage, fullName } = getProfileProps(profile)
      const profileResults = getProfileResults(fullName, searchResults)
      const profileUri = getNameUri(fullName)

      return {
        fullName,
        profileUri,
        image,
        oldImage,
        profileResults,
      }
    })
    .sort((a, b) => {
      if (a.profileResults.length > b.profileResults.length) return -1
      if (a.profileResults.length < b.profileResults.length) return 1
      return null
    })

  const sortedSpeakers =
    searchResults?.resultsSpeaker?.sort((a, b) => {
      if (a < b) return -1
      if (a > b) return 1
      return null
    }) || []

  return (
    <>
      {isSearchLoading && (
        <div
          style={{
            transform: "translate(-30px, 30px)",
            transformOrigin: "center center",
          }}
        >
          <Loading />
        </div>
      )}

      {!isSearchLoading && (
        <div className="table search-results">
          <div className="thead">
            <div className="tr">
              <div
                className="th"
                key={`header-full_name`}
              >{`Results for "${searchWords}"`}</div>
            </div>
          </div>
          <div className="tbody">
            {searchResults?.resultsSpeaker?.length ? (
              <div
                className="tr archive-table-row"
                style={{
                  borderBottom: "2px solid var(--clr-primary)",
                  height: "50px",
                  minHeight: "50px",
                  alignItems: "center",
                }}
              >
                <div className="td">Full interviews</div>
              </div>
            ) : null}

            {sortedSpeakers.map((speaker) => {
              const profile = getProfileProps(
                profiles.find(
                  (p) =>
                    p.full_name.text.replace(/ /g, "_").toLowerCase() ===
                    speaker
                )
              )

              return (
                <div
                  key={`archive-table-row-${speaker}`}
                  className="tr archive-table-row"
                >
                  <div className="td">
                    <div className="profile-image-wrap">
                      <div className="profile-image">
                        {profile.image && (
                          <GatsbyImage
                            alt={`Picture of ${profile.fullName}`}
                            image={profile.image}
                            imgStyle={{
                              objectFit: "cover",
                              visibility: "visible",
                            }}
                          />
                        )}
                        {profile.oldImage && (
                          <GatsbyImage
                            alt={`Old picture of ${profile.fullName}`}
                            image={profile.oldImage}
                            imgStyle={{
                              objectFit: "cover",
                              visibility: "visible",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="td results-cell">
                    <p
                      className="profile-header"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Play
                        size="normal"
                        color="var(--clr-dark-grey)"
                        useCircle
                        onClick={() => {
                          navigate(`/archive/${speaker}`)
                        }}
                      />
                      {profile.fullName}
                    </p>
                  </div>
                </div>
              )
            })}
            {searchResults?.resultsSpeaker?.length ? (
              <div
                className="tr archive-table-row"
                style={{
                  borderBottom: "2px solid var(--clr-primary)",
                  height: "50px",
                  minHeight: "50px",
                  alignItems: "center",
                }}
              >
                <div className="td">Excerpts</div>
              </div>
            ) : null}
            {sortedProfiles.map((profile, profileIdx) => (
              <ArchiveTableSearchRow
                {...profile}
                isOpen={openRow === profileIdx}
                profileIdx={profileIdx}
                key={`profile-${profileIdx}`}
                setOpenRow={setOpenRow}
                searchWords={searchWords}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default ArchiveTableSearchResults
