import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { handleKeyUp } from "../../utils"
import getProfileProps from "../../utils/getProfileProps"
import Years from "../charts/Years"
import "../Menu/MenuButton.css"
import Burger from "../Symbols/Burger"
import "./ArchiveModal.css"

const ArchiveModal = ({ onClose, profile, stickOnClick = true }) => {
  const {
    image,
    gist,
    oldImage,
    fullName,
    age_at_offense,
    deceased_date,
    age_at_interview,
  } = getProfileProps(profile)
  const [isTooltip, setTooltip] = useState(false)

  return (
    <div
      className="archive-modal-wrap fadeinfast menu-buttons"
      onClick={() => {
        if (!stickOnClick) onClose()
      }}
      onKeyUp={(ev) =>
        handleKeyUp(ev, () => {
          if (!stickOnClick) onClose()
        })
      }
      role="button"
      tabIndex="0"
    >
      <button
        className={`menu menu-button menu-light
          ${isTooltip ? " hovered" : ""}
        `}
        style={{
          padding: "10px 14px",
          position: "absolute",
          top: "20px",
          right: "20px",
        }}
        onClick={() => {
          onClose()
        }}
        onMouseEnter={() => {
          setTooltip(true)
        }}
        onMouseLeave={() => {
          setTooltip(false)
        }}
      >
        <Burger isBurgerOpen />
      </button>
      <div className="archive-modal" style={{ position: "relative" }}>
        <div className="modal-content">
          <div className="modal-content-images">
            <div className="old-image">
              {oldImage && (
                <GatsbyImage
                  alt={`Old picture of ${fullName}`}
                  image={oldImage}
                  imgStyle={{
                    objectFit: "cover",
                    visibility: "visible",
                  }}
                />
              )}
            </div>
            <div className="current-image">
              {image && (
                <GatsbyImage
                  alt={`Picture of ${fullName}`}
                  image={image}
                  imgStyle={{
                    objectFit: "cover",
                    visibility: "visible",
                  }}
                />
              )}
            </div>
          </div>
          <div className="modal-content-info">
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <h2>{fullName}</h2>
              <p>{gist}</p>
            </div>
            <div className="years-container">
              <p style={{ margin: 0, fontStyle: "italic" }}>{`${
                age_at_interview - age_at_offense
              } years incarcerated`}</p>
              <span className="liner" />
              <Years
                color={"black"}
                incarcerated={age_at_offense}
                current={age_at_interview}
                deceased_date={deceased_date}
              />
              <span className="liner" />
              <p
                style={{ margin: 0, fontStyle: "italic" }}
              >{`${age_at_interview} years old`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArchiveModal
