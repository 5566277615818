import { GatsbyImage } from "gatsby-plugin-image"
import React, { useMemo, useState } from "react"
import getProfileProps from "../../utils/getProfileProps"
import sortProfiles from "../../utils/sortProfiles"
import Loading from "../Loading"
import "./ArchiveGrid.css"
import ArchiveGridCell from "./ArchiveGridCell"
import GridCellGeneric from "../Grid/GridCellGeneric"

const ArchiveGrid = ({
  profiles,
  sortAsc,
  sortType,
  images,
  setGistProfile,
}) => {
  const [isLoading, setLoading] = useState(false)

  // TODO: Send this to the worker
  const profilesSorted = useMemo(
    () => {
      setLoading(true)
      const newProfiles = sortProfiles(profiles.slice(0), sortType, sortAsc)
      setLoading(false)
      return newProfiles
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [JSON.stringify(profiles), sortAsc, sortType]
  )

  return (
    <>
      <div className="archive-grid">
        {isLoading && <Loading />}

        {profilesSorted.map((profile, profileIdx) => (
          <ArchiveGridCell
            key={`archive-grid-${profileIdx}`}
            profile={profile}
            profileIdx={profileIdx}
            images={images}
            setGistProfile={setGistProfile}
          />
        ))}

        <GridCellGeneric
          image={getProfileProps(profilesSorted[35]).image}
          brightness={0.7}
          classes={"md lg xl"}
        />
        <GridCellGeneric
          image={getProfileProps(profilesSorted[67]).image}
          brightness={0.5}
          classes={"md lg xl"}
        />
        <GridCellGeneric
          image={getProfileProps(profilesSorted[12]).image}
          brightness={0.4}
          classes={"md lg"}
        />
        <GridCellGeneric
          image={getProfileProps(profilesSorted[54]).image}
          brightness={0.25}
          classes={"md lg xl"}
        />
        <GridCellGeneric
          image={getProfileProps(profilesSorted[34]).image}
          brightness={0.18}
          classes={"lg"}
        />
        <GridCellGeneric
          image={getProfileProps(profilesSorted[95]).image}
          brightness={0.1}
          classes={"md lg"}
        />
        <GridCellGeneric
          image={getProfileProps(profiles[31]).image}
          brightness={0.1}
          classes={"md lg xl"}
          message={
            "Nearly 5,000 people are serving life without parole in Louisiana."
          }
        />
      </div>
    </>
  )
}
export default ArchiveGrid
