import React, { useMemo, useState } from "react"
import AutoSizer from "react-virtualized-auto-sizer"
import { FixedSizeList as List } from "react-window"
import sortProfiles from "../../utils/sortProfiles"
import Loading from "../Loading"
import Sort from "../Symbols/Sort"
import "./ArchiveTable.css"
import ArchiveTableRow from "./ArchiveTableRow"
import ArchiveTableRowLarge from "./ArchiveTableRowLarge"

const columnsDesktop = [
  { key: "full_name", label: "Full name" },
  { key: "offense_date", label: "Incarcerated since" },
  { key: "age_at_offense", label: "Age at offense" },
  { key: "age_at_interview", label: "Age at interview" },
  { key: "years", label: "Years incarcerated" },
]

const Row = ({ index: profileIdx, style, data }) => {
  const { profile, gistProfile, setGistProfile } = data[profileIdx]

  return (
    <div style={style} key={`archive-table-row-${profileIdx}`}>
      <ArchiveTableRowLarge
        profile={profile}
        profileIdx={profileIdx}
        gistProfile={gistProfile}
        setGistProfile={setGistProfile}
      />
    </div>
  )
}

const ArchiveTable = ({
  profiles,
  sortType,
  sortAsc,
  setSortedAsc,
  setSortedType,
  gistProfile,
  setGistProfile,
}) => {
  const [isLoading, setLoading] = useState(false)

  // TODO: Send this to the worker
  const profilesSorted = useMemo(
    () => {
      setLoading(true)
      const newProfiles = sortProfiles(profiles.slice(0), sortType, sortAsc)
      setLoading(false)
      return newProfiles
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [JSON.stringify(profiles), sortAsc, sortType]
  )

  return (
    <>
      <div className="archive-table-mobile">
        <div className="table">
          <div className="tbody">
            {isLoading && <Loading />}

            {profilesSorted.map((profile, profileIdx) => {
              return (
                <ArchiveTableRow
                  key={`archive-table-row-${profileIdx}`}
                  profile={profile}
                  profileIdx={profileIdx}
                  gistProfile={gistProfile}
                  setGistProfile={setGistProfile}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div className="archive-table-desktop">
        {isLoading && <Loading />}

        <div className="table">
          <div className="thead">
            <div className="tr">
              {columnsDesktop.map((column) => (
                <div
                  className="th"
                  key={`header-${column.key}`}
                  onClick={() => {
                    if (column.key === sortType.key) {
                      setSortedAsc(!sortAsc)
                    } else {
                      if (column.key === "years") column.key = "offense_date"
                      setSortedType(column)
                    }
                  }}
                >
                  {column.label}
                  <Sort
                    theme="light"
                    enabled={column.key === sortType.key}
                    sortAsc={sortAsc}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="tbody">
            <AutoSizer>
              {({ width, height }) => (
                <List
                  height={height}
                  itemData={profilesSorted.map((profile) => {
                    return { profile, setGistProfile, gistProfile }
                  })}
                  itemCount={profilesSorted.length}
                  itemSize={120}
                  width={width}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    </>
  )
}

export default ArchiveTable
