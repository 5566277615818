import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import "./ArchiveGridCellBackground.css"

const ArchiveGridCellBackground = ({ isHover, image, oldImage, fullName }) => {
  if (!image) return null

  return (
    <div className={`cell-background ${isHover ? "hovered" : ""}`}>
      <GatsbyImage
        alt={`Picture of ${fullName}`}
        image={image}
        imgStyle={{
          objectFit: "cover",
        }}
      />
      {oldImage && (
        <GatsbyImage
          alt={`Old picture of ${fullName}`}
          image={oldImage}
          imgStyle={{
            objectFit: "cover",
          }}
        />
      )}
    </div>
  )
}

export default ArchiveGridCellBackground
