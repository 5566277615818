import React from "react"

const width = 100
const yGap = 10
const radius = 4

const Circle = ({ color, current, incarcerated, deceased_date, i }) => {
  const y = (+current / 10) * yGap - Math.floor(i / 10) * yGap + 5
  const x = width - (i % 10) * 10 - 4
  const opacity = i > current && deceased_date ? 0 : i < current ? 1 : 0 // -0.6 + (120 - i) / (120 - current)

  return (
    <circle
      key={`year-${i}`}
      cx={`${x}px`}
      cy={`${y}px`}
      r={radius}
      fill={
        i < +incarcerated
          ? "#AF9090" //color
          : "#3E0000" /*"hsl(var(--clr-primary-hsl-darker))"*/
      }
      style={{
        opacity,
        visibility: "visible", //visible ? "visible" : "hidden",
      }}
    />
  )
}

const Years = ({ color, incarcerated, current, deceased_date }) => {
  const years = new Array(+current).fill(0)

  return (
    <svg
      width={width}
      height={(+current / 10) * yGap + yGap}
      className="fadein"
    >
      <filter id="blurMe">
        <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
      </filter>
      {years.map((_, i) => {
        return (
          <Circle
            key={`circle-${i}`}
            color={color}
            current={current}
            incarcerated={incarcerated}
            deceased_date={deceased_date}
            i={i}
          />
        )
      })}
    </svg>
  )
}

export default Years
