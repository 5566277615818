import { navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { handleKeyUp } from "../../utils"
import { getSeconds } from "../../utils/index.js"
import Caret from "../Symbols/Caret"
import Play from "../Symbols/Play"
import "./ArchiveTableSearchRow.css"

const ArchiveTableSearchRow = ({
  fullName,
  profileUri,
  profileIdx,
  image,
  oldImage,
  profileResults = [],
  isOpen,
  setOpenRow,
  searchWords,
}) => {
  return (
    <div
      key={`archive-table-row-${profileIdx}`}
      className={`tr archive-table-row ${isOpen ? "open" : ""}`}
    >
      <div className="td">
        <div className="profile-image-wrap">
          <div className="profile-image">
            {image && (
              <GatsbyImage
                alt={`Picture of ${fullName}`}
                image={image}
                imgStyle={{
                  objectFit: "cover",
                  visibility: "visible",
                }}
              />
            )}
            {oldImage && (
              <GatsbyImage
                alt={`Picture of ${fullName}`}
                image={oldImage}
                imgStyle={{
                  objectFit: "cover",
                  visibility: "visible",
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="td results-cell">
        <p className="profile-header">{`${fullName} (${profileResults.length} results)`}</p>
        <button
          onClick={() => {
            setOpenRow(!isOpen ? profileIdx : null)
          }}
          onKeyUp={(ev) =>
            handleKeyUp(ev, () => {
              setOpenRow(!isOpen ? profileIdx : null)
            })
          }
          className="see-more"
        >
          {isOpen ? "See less" : "See more"}
          <div className="caret">
            <Caret color="var(--clr-mid-grey)" />
          </div>
        </button>
        <div className="result-quotes">
          {profileResults.map((r, i) => {
            if (i < 2 || (isOpen && i >= 2)) {
              return (
                <button
                  key={`result-${i}`}
                  className={`quote ${isOpen ? "open" : ""}`}
                  onClick={() => {
                    navigate(
                      `/archive/${profileUri}?t=${getSeconds(
                        r.time
                      )}&search=${searchWords}&transcript=true`
                    )
                  }}
                  onKeyUp={(ev) =>
                    handleKeyUp(ev, () => {
                      navigate(
                        `/archive/${profileUri}?t=${getSeconds(
                          r.time
                        )}&search=${searchWords}&transcript=true`
                      )
                    })
                  }
                >
                  <div className="data">
                    <Play
                      size="normal"
                      color="var(--clr-dark-grey)"
                      useCircle
                      onClick={() => {
                        navigate(
                          `/archive/${profileUri}?t=${getSeconds(
                            r.time
                          )}&search=${searchWords}&transcript=true`
                        )
                      }}
                    />
                    <span className="time">{r.time}</span>
                    <span className="speaker">[{r.speaker}]</span>
                  </div>

                  <p className="content">{r.content}</p>
                </button>
              )
            }
            return null
          })}
        </div>
      </div>
    </div>
  )
}

export default ArchiveTableSearchRow
