import React, { useState } from "react"
import { handleKeyUp } from "../../utils"

const Options = ({ onClick, doHover, color = "#ffffff" }) => {
  const [isHover, setHover] = useState(doHover)
  let viewBox = "0 0 24 24"
  let width = "24px"
  let height = "24px"
  let transform = "none"

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      style={{
        pointerEvents: "all",
      }}
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onKeyUp={(ev) => handleKeyUp(ev, onClick)}
    >
      <g
        id="Typography---UI-Comp"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        style={{
          transform,
          opacity: isHover ? 0.5 : 1,
          transition: "opacity 0.4s",
          cursor: "pointer",
        }}
      >
        <circle id="av" fill={color} cx="6" cy="12" r="2"></circle>
        <circle id="av" fill={color} cx="12" cy="12" r="2"></circle>
        <circle id="av" fill={color} cx="18" cy="12" r="2"></circle>
      </g>
    </svg>
  )
}

export default Options
