import { navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import getProfileProps from "../../utils/getProfileProps"
import Options from "../Symbols/Options"
import Play from "../Symbols/Play"
import "./ArchiveTableRow.css"

const ArchiveTableRow = ({ profile, profileIdx, setGistProfile }) => {
  const [isHover, setHover] = useState(false)

  const {
    image,
    oldImage,
    fullName,
    date_of_offense,
    age_at_offense,
    deceased_date,
    time_served,
    age_at_interview,
    archive_video_link,
  } = getProfileProps(profile)

  return (
    <div
      key={`archive-table-row-${profileIdx}`}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      className={`tr ${isHover ? "hovered" : ""} archive-table-row`}
    >
      <>
        <div className="td">
          <div className="image-wrap">
            {image && (
              <GatsbyImage
                alt={"Picture at the time of the interview"}
                image={image}
                imgStyle={{
                  objectFit: "cover",
                  visibility: "visible",
                }}
              />
            )}
            {oldImage && (
              <GatsbyImage
                alt={"Picture at the time of detention."}
                image={oldImage}
                imgStyle={{
                  objectFit: "contain !important",
                  visibility: "visible",
                }}
              />
            )}
          </div>
          <div
            className="full-name"
            onClick={() => {
              const profileUri = fullName.toLowerCase().replace(/ /g, "_")
              archive_video_link && navigate(`/archive/${profileUri}`)
            }}
          >
            <Play size="normal" color={"white"} />
          </div>
        </div>
        <div className="td">
          <div className="fields">
            <div className="fields-grid">
              <p className="full-name">{fullName}</p>
              {deceased_date && (
                <p className="deceased">{`Deceased on: ${deceased_date.getFullYear()}`}</p>
              )}
              <div className="field">
                <p>{"Incarcerated since"}</p>
                <p>{date_of_offense}</p>
              </div>
              <div className="field">
                <p>{"Age at offense"}</p>
                <p>{age_at_offense}</p>
              </div>
              <div className="field">
                <p>{"Age"}</p>
                <p>{age_at_interview}</p>
              </div>
              <div className="field">
                <p>{"Years incarcerated"}</p>
                <p>{time_served}</p>
              </div>
              <button
                className="options"
                onClick={(e) => {
                  e.stopPropagation()
                  setGistProfile(fullName)
                }}
              >
                <Options color="var(--clr-black)" />
              </button>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default ArchiveTableRow
